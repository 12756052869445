/** @jsx jsx */
import { css, jsx } from "@emotion/react";
// import { useSearchSettings } from "@municipio/gatsby-theme-basic/src/hooks";
import SelectField from "@whitespace/gatsby-plugin-search/src/components/SelectField";
import PropTypes from "prop-types";
import { useSortBy, useInstantSearch } from "react-instantsearch-hooks-web";

SearchMenu.propTypes = {
  hideLabel: PropTypes.bool,
  label: PropTypes.node,
};

export default function SearchMenu(props) {
  let { label, hideLabel, ...restProps } = props;
  // const { t } = useTranslation();
  const {
    // initialIndex,
    currentRefinement,
    options,
    refine,
    // canRefine,
  } = useSortBy(props);
  const { results } = useInstantSearch();

  const name = "sortBy";

  if (!results || results.intercepted) {
    return null;
  }

  return (
    <div>
      <div
        css={css`
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        `}
      >
        <label htmlFor="search-sort-by">{label}:</label>
      </div>
      <SelectField
        name={name}
        isMulti={false}
        value={currentRefinement}
        onChange={(value) => {
          refine(value);
        }}
        options={options}
        inputId={"search-sort-by"}
        {...restProps}
      />
    </div>
  );
}
