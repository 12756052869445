/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { H, Section } from "@jfrk/react-heading-levels";
import { PageGrid, PageGridItem } from "@whitespace/components";
import SiteSearch from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SiteSearch";
import PropTypes from "prop-types";
import { Fragment } from "react";

import SEO from "../../../components/seo";
// import { useTranslation } from "react-i18next";

SearchTemplate.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default function SearchTemplate({ pageContext: { title } }) {
  // const { t } = useTranslation();
  return (
    <Fragment>
      <SEO title="Sök" />
      <PageGrid
        css={css`
          margin-top: 1rem;
          margin-bottom: 2rem;
          padding-left: var(--layout-mobile-padding, 0.5rem);
          padding-right: var(--layout-mobile-padding, 0.5rem);
        `}
      >
        <PageGridItem>
          <H className="c-article__title">{title}</H>
          <Section>
            <SiteSearch />
          </Section>
        </PageGridItem>
      </PageGrid>
    </Fragment>
  );
}
