/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { searchPluginConfigContext } from "@whitespace/gatsby-plugin-search/src/contexts";
// import { Fragment } from "react";
// import PropTypes from "prop-types";
import SearchBox from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchBox";
// import SearchDebug from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchDebug";
import SearchHit from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchHit";
import SearchHits from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchHits";
// import SearchRangeInput from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchRangeInput";
import SearchMenuButtonGroup from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchMenuButtonGroup";
import SearchPagination from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchPagination";
import SearchProvider from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchProvider";
import SearchSortBy from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchSortBy";
// import SearchSummary from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchSummary";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Configure } from "react-instantsearch-hooks-web";

import Notice from "../../../../components/Notice";

SiteSearch.propTypes = {};

export default function SiteSearch() {
  // const {
  //   indexUiState,
  //   setIndexUiState,
  //   uiState,
  //   setUiState,
  //   results,
  //   scopedResults,
  //   refresh,
  //   use,
  // } = useInstantSearch();

  const { t, i18n } = useTranslation();
  const searchPluginConfig = useContext(searchPluginConfigContext);
  const facets = useMemo(() => {
    return {
      ...searchPluginConfig?.facets,
      contentType:
        searchPluginConfig?.facets?.contentType === false
          ? undefined
          : {
              attribute: "contentType.name",
              ...searchPluginConfig?.facets?.contentType,
              options: (
                searchPluginConfig?.facets?.contentType?.options || [
                  "page",
                  "post",
                ]
              ).reduce(
                (obj, value) => (
                  (obj[value] = t(`search.facetLabels.contentType.${value}`)),
                  obj
                ),
                {
                  "": {
                    label: t("search.facetLabels.contentType.any"),
                    count: (item, items) =>
                      items.reduce(
                        (acc, item) => acc + Number(item.count) || 0,
                        0,
                      ),
                  },
                },
              ),
            },
    };
  }, []);

  return (
    <SearchProvider
      routing={true}
      // transformParams={(params) => ({
      //   ...params,
      //   filters: [params.filters, `language:${i18n.language}`]
      //     .filter(Boolean)
      //     .join(" AND "),
      // })}
    >
      {({ indexName }) => (
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
          `}
        >
          <Configure filters={`language:${i18n.language}`} />
          <SearchBox searchAsYouType={false} autoFocus={true} />
          {!!facets.contentType && (
            <SearchMenuButtonGroup
              label={t("search.facets.contentType.label")}
              hideLabel
              {...facets.contentType}
              // Optional parameters
              // limit={number}
              // showMore={boolean}
              // showMoreLimit={number}
              // sortBy={string[] | function}
            />
          )}
          <div
            css={css`
              display: grid;
              align-items: end;
              @media (min-width: 576px) {
                grid-template-columns: minmax(200px, 300px) 1fr 1fr;
              }
            `}
          >
            <SearchSortBy
              label={t("sortBy")}
              items={[
                { label: t("search.sortBy.relevance"), value: indexName },
                {
                  label: t("search.sortBy.publishDate"),
                  value: `${indexName}_publish_date`,
                },
              ]}
            />
            {/* <SearchSummary /> */}
          </div>
          {/* <SearchDebug /> */}
          <SearchHits
            hitComponent={SearchHit}
            emptyResultsMessage={
              <Notice
                css={css`
                  &:after {
                    content: "";
                    display: block;
                    margin-top: 99rem;
                    margin-bottom: -99rem;
                  }
                `}
              >
                <p>{`Din sökning gav inga träffar.`}</p>
                <ul>
                  <li>Kontrollera att du stavat rätt.</li>
                  <li>Sök på mindre specifika ord.</li>
                  <li>Prova med ett alternativt ord eller en synonym.</li>
                </ul>
              </Notice>
            }
          />
          <SearchPagination
          // Optional props
          // totalPages={number}
          // padding={number}
          // showFirst={boolean}
          // showPrevious={boolean}
          // showNext={boolean}
          // showLast={boolean}
          // classNames={object}
          // translations={object}
          />
        </div>
      )}
    </SearchProvider>
  );
}
